import { useEffect, useState } from "react";

const Cookies = () => {
    const [cookiesDisplay, setCookiesDisplay] = useState();

    useEffect(() => {
        if (localStorage.getItem("cookiesApproval") === "true") {
            setCookiesDisplay(false)
        } else {
            setCookiesDisplay(true)
        }
	}, []);

    const handleCookiesApproval = () => {
        localStorage.setItem("cookiesApproval", true);
        setCookiesDisplay(false)
    } 

    return (
        <>
            {cookiesDisplay ? (
                <div className="cookies-container">
                    <div className="text">
                        <h4>Gérer vos cookies</h4>
                        <p>En cliquant sur « Accepter », vous acceptez l'utilisation des cookies pour mesurer l'audience sur le site.</p>
                    </div>
                    <div className="buttons">
                        <button className="button" onClick={handleCookiesApproval}>Accepter</button>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>

    );
};

export default Cookies;