import Home from "./components/Home";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Cookies from "./components/Cookies";
import Team from "./components/Team";

const App = () => {
	return (
		<div className="App">
            <Cookies />
			<Home />
			<Services />
            <Team />
			<Contact />
		</div>
	);
};

export default App;