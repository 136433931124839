import whiteLogo from "../assets/img/logo/full_white.png"
import scrollImg from "../assets/img/pictos/scroll.png"

const Home = () => {
	return (
		<section className="home-section">
			<div className="hero-container">
                <div className="hero-container__logo">
                    <img src={whiteLogo} alt="Logo de Syrahgence" />
                </div>
                <div className="hero-container__tag">
                    <div className="hero-container__tag--title">
                        <h1>Agence spécialisée - stratégies & conseils</h1>
                        <div className="white-undeerline"></div>
                    </div>
                    <h2>Des professionnels du vin au service des métiers du vin.</h2>
                </div>
            </div>
            <a href="#services" className="cta-absolute">
                    <img src={scrollImg} alt="Découvrez les services" />
                </a>
		</section>
	);
};

export default Home;