import React, { useEffect, useState } from 'react';

// img
import auditImg from "../assets/img/pictos/audit.png";
import commercialImg from "../assets/img/pictos/commercial.png";
import marketingImg from "../assets/img/pictos/marketing.png";
import communicationImg from "../assets/img/pictos/communication.png";
import digitalImg from "../assets/img/pictos/digital.png";

const Services = () => {
    const [offset, setOffset] = useState(0);
    const [pictosDisplay, setPictosDisplay] = useState(false);

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);

        

        if (offset > 350) {
            setPictosDisplay(true);
            const illustrations = document.querySelectorAll(".illustration");
            for (let i = 0; i < illustrations.length; i++) {
                illustrations[i].style.animationDelay = `${600 + 300 * i}ms`;
            }
        }

        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, [offset]);



	return (
		<section className="services-section" id="services">
            <div className="services-container">
                <h3>nos services a 360</h3>
                <ul className="services-list">
                    <li className="service">
                        <div className="service__img">
                            {pictosDisplay && <img className='illustration' src={auditImg} alt="Audit" />}
                        </div>
                        <h4>Audit</h4>
                        <p><span></span>Etude concurrentielle<br/></p>
                        <p><span></span>Positionnement et stratégie<br/></p>                        
                        <p><span></span>Définition des objectifs et plan d'actions<br/></p>
                    </li>
                    <li className="service">
                        <div className="service__img">
                            {pictosDisplay && <img className='illustration' src={commercialImg} alt="Audit" />}
                        </div>
                        <h4>Commercial</h4>
                        <p><span></span>Politique commerciale et tarifaire<br/></p>
                        <p><span></span>Positionnement produit<br/></p>                        
                        <p><span></span>Réflexion de gammes<br/></p>
                        <p><span></span>Merchandising<br/></p>
                    </li>
                    <li className="service">
                        <div className="service__img">
                            {pictosDisplay && <img className='illustration' src={marketingImg} alt="Audit" />}
                        </div>
                        <h4>Marketing</h4>
                        <p><span></span>Création ou refonte identitaire<br/></p>
                        <p><span></span>Design packaging produit<br/></p>
                        <p><span></span>Création supports promotionnels<br/></p>
                    </li>
                    <li className="service">
                        <div className="service__img">
                            {pictosDisplay && <img className='illustration' src={communicationImg} alt="Audit" />}
                        </div>
                        <h4>Communication</h4>
                        <p><span></span>Définition ligne éditoriale<br/></p>
                        <p><span></span>Plan de communication<br/></p>
                        <p><span></span>Elaboration de campagne<br/></p>
                    </li>
                    <li className="service">
                        <div className="service__img">
                            {pictosDisplay && <img className='illustration' src={digitalImg} alt="Audit" />}
                        </div>
                        <h4>Digital</h4>
                        <p><span></span>Création de site internet<br/></p>
                        <p><span></span>Stratégie social media<br/></p>
                        <p><span></span>Web marketing<br/></p>
                        <p><span></span>Formations<br/></p>
                    </li>
                </ul>
            </div>
		</section>
	);
};

export default Services;

// logo credits 
// https://www.flaticon.com/fr/auteurs/freepik