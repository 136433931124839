

const Legal = () => {
    const handleCGVDisplay = () => {
        const legal = document.getElementById("legal");
        legal.style.transform = "translateY(100vh)"
    }
	return (
		<section className="legal-section" id="legal">
            <div id="return" onClick={handleCGVDisplay}>
                ← Retour
            </div>
			<h3>MENTIONS LEGALES</h3>
            <h5>Propriétaire et éditeur du site : </h5>
            <p>
                Syrahgence représenté par Camille Gerakis (EI) immatriculé 948 001 706 R.C.S Marseille.<br/>
                Adresse : 146 Chemin des Prud’Hommes 13010 à Marseille<br/>
                Tél : <a href="tel:0767454859">07 67 45 48 59</a><br/>
                Mail : <a href="mailto:bienvenue@syrahgence.fr">bienvenue@syrahgence.fr</a>
                <br/><br/>

                SYRAHGENCE est un nom déposé. Le nom de domaine www.syrahgence.fr est réservé.
            </p>
            <h5>Identité de l’hébergeur : </h5>
            <p>
                OVH<br/>
                SAS au capital de 10 174 560 €<br/><br/>

                RCS Lille Métropole 424 761 419 00045<br/>
                Code APE 2620Z<br/>
                N° TVA : FR 22 424 761 419<br/>
                Siège social : 2 rue Kellermann - 59100 Roubaix - France<br/><br/>
                
                Président : Michel Paulin<br/>
                OVH SAS est une filiale de la société OVH Groupe SA, société immatriculée au RCS de Lille sous le numéro 537 407 926 sise 2, rue Kellermann, 59100 Roubaix.<br/>
            </p>
            <h5>Données personnelles : </h5>
            <p>Conformément à la loi « Informatique et Libertés » du 6 janvier 1978 modifiée en 2004, vous bénéficiez d'un droit d'accès et de rectification aux informations vous concernant (rectification, actualisation, suppression des données). Vous pouvez l’exercer en vous adressant à <a href="mailto:bienvenue@syrahgence.fr">bienvenue@syrahgence.fr</a>.</p>
            <h5>Cookie : </h5>
            <p>L’utilisateur est informé que lors de sa navigation, un cookie peut être installé. La mise en place de ce système nous permet la récupération de données à des fins statistiques (Google Analytics) dans le but d’améliorer notre site. Les données récoltées ne sont en aucun cas cédées ou vendues à tiers. Un dispositif est mis en place sur notre site afin de vous opposer à la mise en place de ces cookies.</p>
            <h5>Contenu du site : </h5>
            <p>
                Nous déclinons toute responsabilité si d’éventuelles erreurs ou omissions portant sur des informations mises en ligne sur le site.<br/><br/>
                Nous nous réservons le droit de modifier, corriger ou supprimer le contenu du site sans préavis.
            </p>
            <h5>Droit applicable et conditions d’utilisation : </h5>
            <p>
                Les conditions d’utilisation de notre site sont régies par la Loi Française. L’utilisation de ce site entraîne votre adhésion aux conditions d’utilisation.<br/><br/>
                Ce site ainsi que tous les éléments le composants sont protégés par le code de la Propriété Intellectuelle (articles 112-2). La copie ou reproduction partielle est interdite sans l’accord des auteurs et engage une responsabilité civile et pénale en cas manquement à cette interdiction.<br/><br/>
                Les liens hypertextes qui pourraient être mis en place vers notre site sont soumis à un accord préalable de SYRAHGENCE. Par ailleurs, nous ne pourrons pas être tenus responsables pour le contenu des sites vers lesquels les liens hypertextes mis en place sur notre site renvoient. 
            </p>
		</section>
	);
};

export default Legal;