
// img 
import logoGlassRed from "../assets/img/logo/glass_red.png"
import mailIco from "../assets/img/pictos/enveloppe.png"
import phoneIco from "../assets/img/pictos/phone.png"
import instaIco from "../assets/img/pictos/instagram.png"
import linkedinIco from "../assets/img/pictos/linkedin.png"
import Legal from "./Legal"


const Home = () => {
    const handleCGVDisplay = () => {
        const legal = document.getElementById("legal");
        legal.style.transform = "translateY(0)"
    }

	return (
		<section className="contact-section">
			<div className="text-part">
                <div className="text-container">
                    <h3>Contact</h3>
                    <p>
                        Vous avez un projet ? Vous souhaitez avoir plus d'informations sur nos services et nos tarifs ? 
                        <br/>
                        <br/>
                        N'hésitez pas à nous contacter ici :
                    </p>
                    <div className="text-part__information">
                        <div className="contact">
                            <a className="text-part__information--mail" href="mailto:bienvenue@syrahgence.fr">
                                <img src={mailIco} alt="Contactez Syragence par mail" />
                                bienvenue@syrahgence.fr
                            </a>
                            <a className="text-part__information--mail"  href="tel:0767454859">
                                <img src={phoneIco} alt="Contactez Syragence par mail" />
                                07 67 45 48 59
                            </a>
                        </div>
                            <p>
                                Rejoignez-nous sur nos réseaux sociaux pour ne rien manquer : 
                            </p>
                        <div className="text-part__information--social">
                            <a href="https://www.instagram.com/syrahgence/?hl=fr" target="_blank" rel="noopener noreferrer" className="social-network">
                                <img src={instaIco} alt="Retrouvez nous sur Instagram" />
                            </a>
                            <a href="https://www.linkedin.com/company/syrahgence/" target="_blank" rel="noopener noreferrer" className="social-network">
                                <img src={linkedinIco} alt="Retrouvez nous sur Instagram" />
                            </a>
                            <div className="social-network">
                                @syrahgence
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-part__cgv" onClick={handleCGVDisplay}>
                    Mentions Légales
                </div>
            </div>
            <div className="form-part">
                <img src={logoGlassRed} alt="Logo de Syrahgence" />
            </div>
            <Legal />
		</section>
	);
};

export default Home;